

























import{ Component, Vue }from 'vue-property-decorator';
import CusTable from '@/components/table';
import CampusDetail from './components/campus-detail.vue';

@Component
export default class extends Vue{

  private nodes = [
    { label: '省份', key: 'provinceId', type: 'select', child: 'cityId', region: 'province' },
    { label: '城市', key: 'cityId', type: 'select' },
    { label: '校区名称', key: 'schoolName', type: 'input' },
  ]

  $refs!: { table: CusTable }

  private async state(e) {
    const res: any = await this.axios.post('/admin/school/saveOrUpdate', { id: e.id, status: Number(!e.status) });
    res.result && this.$message.success('修改状态成功~！') && this.$refs.table.request();
  }


  private update(data =  null) {
    this.$modal.create({
      title: `${data ? '修改' : '添加'}校区`,
      width: 400,
      props: { data },
      component: CampusDetail
    }).$on('close', res => res && this.$refs.table.request());
  }

  private async remove(e) {
    let confirm = await this.$confirm('此操作将永久删除该校区, 是否继续?', '提示', { type: 'warning' });
    if (confirm) {
      const res: any = await this.axios.post(`/admin/school/deleteById/${e.id}`);
      res.result && this.$message.success('删除校区成功~！') && this.$refs.table.request();
    }
  }

}
