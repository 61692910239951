
























import { ElForm } from 'element-ui/types/form';
import{ Component, Vue, Prop }from 'vue-property-decorator';
import { pca } from 'area-data';

@Component
export default class extends Vue{

  @Prop({ default: () => new Object() }) data!: any;

  private provinceList = Object.entries(pca[86]);
  private cityList: any[] = this.data.id ? Object.entries(pca[this.data.provinceId]) : [];

  private formGroup = {
    id: this.data.id || null,
    provinceId: this.data.provinceId || null,
    cityId: this.data.cityId || null,
    schoolName: this.data.schoolName || null,
    schoolAddress: this.data.schoolAddress || null,
    schoolMobile: this.data.schoolMobile || null
  }
  private rules = {
    provinceId: [ { required: true, message: '请选择选择省份' } ],
    cityId: [ { required: true, message: '请选择城市' } ],
    schoolName: [ { required: true, message: '请输入校区名称' } ],
    schoolMobile: [ { required: true, message: '请输入校区电话' } ],
    schoolAddress: [ { required: true, message: '请输入校区地址' } ],
  }
  private provinceChange(e) {
    this.formGroup.cityId = null;
    this.cityList = e ? Object.entries(pca[e]) : [];
  }

  $refs!: { form: ElForm }
  public async save() {
    const valid = await this.$refs.form.validate();
    if (valid) {
      let provinceName = (this.provinceList.find(i => i[0] === this.formGroup.provinceId) as any[])[1];
      let cityName = (this.cityList.find(i => i[0] === this.formGroup.cityId) as any[])[1];
      const res = await this.axios.post('/admin/school/saveOrUpdate', Object.assign({ provinceName, cityName }, this.formGroup));
      res ? (this.$emit('close', res), this.$message.success('添加校区成功')) : this.$message.warning('添加校区失败');
    }
  }
}
